import {
    PartialTheme,
} from "@fluentui/react";

const lightTheme: PartialTheme = {
    palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const darkTheme: PartialTheme = {
    palette: {
        themePrimary: '#0088c4',
        themeLighterAlt: '#000508',
        themeLighter: '#00161f',
        themeLight: '#00293b',
        themeTertiary: '#005276',
        themeSecondary: '#0079ad',
        themeDarkAlt: '#1494ca',
        themeDark: '#33a2d2',
        themeDarker: '#62b9de',
        neutralLighterAlt: '#393b3c',
        neutralLighter: '#414244',
        neutralLight: '#4e4f51',
        neutralQuaternaryAlt: '#565759',
        neutralQuaternary: '#5c5e5f',
        neutralTertiaryAlt: '#77797a',
        neutralTertiary: '#fafafa',
        neutralSecondary: '#fbfbfb',
        neutralPrimaryAlt: '#fcfcfc',
        neutralPrimary: '#f8f8f8',
        neutralDark: '#fdfdfd',
        black: '#fefefe',
        white: '#303132',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const lightOcean: PartialTheme = {
    palette: {
        themePrimary: '#004884',
        themeLighterAlt: '#f0f6fa',
        themeLighter: '#c6daeb',
        themeLight: '#99bdda',
        themeTertiary: '#4985b6',
        themeSecondary: '#125993',
        themeDarkAlt: '#004277',
        themeDark: '#003765',
        themeDarker: '#00294a',
        neutralLighterAlt: '#e9e9f1',
        neutralLighter: '#e6e6ed',
        neutralLight: '#dcdce3',
        neutralQuaternaryAlt: '#cdcdd3',
        neutralQuaternary: '#c4c4ca',
        neutralTertiaryAlt: '#bcbcc2',
        neutralTertiary: '#a6acb7',
        neutralSecondary: '#8c93a0',
        neutralPrimaryAlt: '#747b88',
        neutralPrimary: '#20242c',
        neutralDark: '#474d5a',
        black: '#333843',
        white: '#f0f0f8',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const darkOcean: PartialTheme = {
    palette: {
        themePrimary: '#00c8f4',
        themeLighterAlt: '#00080a',
        themeLighter: '#002027',
        themeLight: '#003c49',
        themeTertiary: '#007893',
        themeSecondary: '#00b0d7',
        themeDarkAlt: '#19cdf6',
        themeDark: '#3bd5f7',
        themeDarker: '#6ee0f9',
        neutralLighterAlt: '#1f232a',
        neutralLighter: '#1e2229',
        neutralLight: '#1d2128',
        neutralQuaternaryAlt: '#1b1e25',
        neutralQuaternary: '#1a1d23',
        neutralTertiaryAlt: '#191c22',
        neutralTertiary: '#e9e9f5',
        neutralSecondary: '#ededf6',
        neutralPrimaryAlt: '#f0f0f8',
        neutralPrimary: '#e0e0f0',
        neutralDark: '#f7f7fb',
        black: '#fbfbfd',
        white: '#20242c',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const paper: PartialTheme = {
    palette: {
        themePrimary: '#b09060',
        themeLighterAlt: '#fcfaf7',
        themeLighter: '#f2ebe1',
        themeLight: '#e7dbc8',
        themeTertiary: '#d0b998',
        themeSecondary: '#b99c70',
        themeDarkAlt: '#9e8257',
        themeDark: '#866e4a',
        themeDarker: '#635136',
        neutralLighterAlt: '#f1ede9',
        neutralLighter: '#ede9e6',
        neutralLight: '#e3e0dc',
        neutralQuaternaryAlt: '#d3d0cd',
        neutralQuaternary: '#cac7c4',
        neutralTertiaryAlt: '#c2bfbc',
        neutralTertiary: '#bda38e',
        neutralSecondary: '#a88971',
        neutralPrimaryAlt: '#927257',
        neutralPrimary: '#3c2410',
        neutralDark: '#68472d',
        black: '#53351d',
        white: '#f8f4f0',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const cardboard: PartialTheme = {
    palette: {
        themePrimary: '#b09060',
        themeLighterAlt: '#070604',
        themeLighter: '#1c170f',
        themeLight: '#352b1d',
        themeTertiary: '#6a573a',
        themeSecondary: '#9b7f55',
        themeDarkAlt: '#b89a6d',
        themeDark: '#c3a880',
        themeDarker: '#d3bd9e',
        neutralLighterAlt: '#876a52',
        neutralLighter: '#8c6f57',
        neutralLight: '#947860',
        neutralQuaternaryAlt: '#997d66',
        neutralQuaternary: '#9d816b',
        neutralTertiaryAlt: '#ae947f',
        neutralTertiary: '#faf7f5',
        neutralSecondary: '#fbf9f7',
        neutralPrimaryAlt: '#fcfaf8',
        neutralPrimary: '#f8f4f0',
        neutralDark: '#fdfcfb',
        black: '#fefefd',
        white: '#83654d',
    },
    semanticColors: {
        errorText: '#802020',
    },
};

const softDark: PartialTheme = {
    palette: {
        themePrimary: '#6090a0',
        themeLighterAlt: '#040606',
        themeLighter: '#0f171a',
        themeLight: '#1d2b30',
        themeTertiary: '#3a5760',
        themeSecondary: '#557f8d',
        themeDarkAlt: '#6d9baa',
        themeDark: '#80a9b7',
        themeDarker: '#9dbfca',
        neutralLighterAlt: '#484848',
        neutralLighter: '#4f4f4f',
        neutralLight: '#5b5b5b',
        neutralQuaternaryAlt: '#636363',
        neutralQuaternary: '#696969',
        neutralTertiaryAlt: '#828282',
        neutralTertiary: '#303030',
        neutralSecondary: '#606060',
        neutralPrimaryAlt: '#8d8d8d',
        neutralPrimary: '#a0a0a0',
        neutralDark: '#b7b7b7',
        black: '#cacaca',
        white: '#404040',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const softLight: PartialTheme = {
    palette: {
        themePrimary: '#204050',
        themeLighterAlt: '#f2f6f8',
        themeLighter: '#cddce3',
        themeLight: '#a6beca',
        themeTertiary: '#608395',
        themeSecondary: '#2f5364',
        themeDarkAlt: '#1c3947',
        themeDark: '#18303c',
        themeDarker: '#12232c',
        neutralLighterAlt: '#bababa',
        neutralLighter: '#b7b7b7',
        neutralLight: '#b0b0b0',
        neutralQuaternaryAlt: '#a4a4a4',
        neutralQuaternary: '#9c9c9c',
        neutralTertiaryAlt: '#969696',
        neutralTertiary: '#131313',
        neutralSecondary: '#262626',
        neutralPrimaryAlt: '#383838',
        neutralPrimary: '#404040',
        neutralDark: '#6e6e6e',
        black: '#949494',
        white: '#c0c0c0',
    },
    semanticColors: {
        errorText: '#e06060',
    },
};

const darkRose: PartialTheme = {
    palette: {
        themePrimary: '#c08080',
        themeLighterAlt: '#080505',
        themeLighter: '#1f1515',
        themeLight: '#392626',
        themeTertiary: '#734d4d',
        themeSecondary: '#a87171',
        themeDarkAlt: '#c68b8b',
        themeDark: '#cf9b9b',
        themeDarker: '#dbb3b3',
        neutralLighterAlt: '#5e3f3f',
        neutralLighter: '#5d3e3e',
        neutralLight: '#593c3c',
        neutralQuaternaryAlt: '#533838',
        neutralQuaternary: '#4f3535',
        neutralTertiaryAlt: '#4c3333',
        neutralTertiary: '#aba6a6',
        neutralSecondary: '#8f8b8b',
        neutralPrimaryAlt: '#736f6f',
        neutralPrimary: '#fff8f8',
        neutralDark: '#3b3939',
        black: '#1f1e1e',
        white: '#604040',
        },
    semanticColors: {
        errorText: '#ff5050',
    },
};

const lightRose: PartialTheme = {
    palette: {
        themePrimary: '#802020',
        themeLighterAlt: '#faf2f2',
        themeLighter: '#ebcece',
        themeLight: '#d9a8a8',
        themeTertiary: '#b36262',
        themeSecondary: '#8f3131',
        themeDarkAlt: '#731d1d',
        themeDark: '#611818',
        themeDarker: '#471212',
        neutralLighterAlt: '#f8f1f1',
        neutralLighter: '#f4eded',
        neutralLight: '#eae3e3',
        neutralQuaternaryAlt: '#dad3d3',
        neutralQuaternary: '#d0caca',
        neutralTertiaryAlt: '#c8c2c2',
        neutralTertiary: '#d0bbbb',
        neutralSecondary: '#a08080',
        neutralPrimaryAlt: '#745252',
        neutralPrimary: '#604040',
        neutralDark: '#4a3131',
        black: '#362424',
        white: '#fff8f8',
    },
    semanticColors: {
        errorText: '#d04040',
    },
};

const darkForest: PartialTheme = {
    palette: {
        themePrimary: '#70c080',
        themeLighterAlt: '#f8fcf9',
        themeLighter: '#e4f5e8',
        themeLight: '#ceecd4',
        themeTertiary: '#549161',
        themeSecondary: '#7dc78c',
        themeDarkAlt: '#64ac72',
        themeDark: '#549161',
        themeDarker: '#3e6b47',
        neutralLighterAlt: '#0b2d15',
        neutralLighter: '#0f361a',
        neutralLight: '#164323',
        neutralQuaternaryAlt: '#1c4c29',
        neutralQuaternary: '#20532f',
        neutralTertiaryAlt: '#377047',
        neutralTertiary: '#eafff6',
        neutralSecondary: '#eefff8',
        neutralPrimaryAlt: '#f1fff9',
        neutralPrimary: '#e0fff2',
        neutralDark: '#f8fffc',
        black: '#fbfffd',
        white: '#082410',
    },
    semanticColors: {
        errorText: '#d04040',
    },
};

const lightForest: PartialTheme = {
    palette: {
        themePrimary: '#208030',
        themeLighterAlt: '#f2faf4',
        themeLighter: '#ceebd3',
        themeLight: '#a8d9b0',
        themeTertiary: '#62b370',
        themeSecondary: '#318f40',
        themeDarkAlt: '#1d732b',
        themeDark: '#186124',
        themeDarker: '#12471b',
        neutralLighterAlt: '#f1f8f3',
        neutralLighter: '#edf4ef',
        neutralLight: '#e3eae5',
        neutralQuaternaryAlt: '#d3dad5',
        neutralQuaternary: '#cad0cc',
        neutralTertiaryAlt: '#c2c8c4',
        neutralTertiary: '#b0d0bb',
        neutralSecondary: '#70a080',
        neutralPrimaryAlt: '#417452',
        neutralPrimary: '#306040',
        neutralDark: '#254a31',
        black: '#1b3624',
        white: '#f8fffa',
    },
    semanticColors: {
        errorText: '#d04040',
    },
};

export const themeDictionary: Record<string, { name: string, theme: PartialTheme }> = {
    "dark": { name: "Dark", theme: darkTheme },
    "light": { name: "Light", theme: lightTheme },
    "cardboard": { name: "Cardboard", theme: cardboard },
    "paper": { name: "Paper", theme: paper },
    "softDark": { name: "Soft dark", theme: softDark },
    "softLight": { name: "Soft light", theme: softLight },
    "darkOcean": { name: "Dark ocean", theme: darkOcean },
    "lightOcean": { name: "Light ocean", theme: lightOcean },
    "darkRose": { name: "Dark rose", theme: darkRose },
    "lightRose": { name: "Light rose", theme: lightRose },
    "darkForest": { name: "Dark forest", theme: darkForest },
    "lightForest": { name: "Light forest", theme: lightForest },
};

export const defaultThemeKey = "dark";